import { getPostPageSize } from '../constants/pagination';
import { startLoadingCategory } from './start-loading-category';
import { stopLoadingCategory } from './stop-loading-category';
import fetchPosts from './fetch-posts';
import { actionPromiseCache } from '../services/action-promise-cache';
import { isSSR } from '../../common/store/basic-params/basic-params-selectors';
import { markPostsAsSeen } from '../containers/user-activity';
import { isFeedLayoutType } from '../selectors/layout-selectors';
import { getContextToken } from '../services/context-token';
import {
  getIsCommentsInFeedEnabled,
  getPostFeedCommentsCount,
} from '../selectors/app-settings-selectors';
import { getCommentsPaginationConfig } from '../components/post-feed-item/post-feed.utils';
import { getCategory } from '../../common/selectors/categories-selectors';

const finishLoading = (dispatch, categoryId) => {
  dispatch(stopLoadingCategory(categoryId));
};

export const fetchCategoryPosts = actionPromiseCache(
  ({ siteMemberId, categoryId, page, sort, postType, pageSize }) =>
    (dispatch, getState, { wixCommentsApiRef }) => {
      const state = getState();
      const isFeedLayout = isFeedLayoutType(state, state);
      const isCommentsEnabled = getIsCommentsInFeedEnabled(state, state);
      const commentsCount = Number(getPostFeedCommentsCount(state, state));
      pageSize = pageSize || getPostPageSize(state, state);

      dispatch(startLoadingCategory(categoryId));
      const promise = dispatch(
        fetchPosts({ siteMemberId, categoryId, page, pageSize, sort, postType }),
      );

      if (!isSSR(state)) {
        promise.then((response) => {
          if (response.body.length > 0) {
            dispatch(markPostsAsSeen(response.body.map((p) => p._id)));
          }
        });
      }

      let commentsPromise = Promise.resolve();

      if (isFeedLayout && isCommentsEnabled && wixCommentsApiRef.current) {
        const commentsRequest = promise.then((response) => {
          return wixCommentsApiRef.current.bulkFetchComments(
            response.body.map((p) => {
              const category = getCategory(state, p.categoryId);
              const paginationConfig = getCommentsPaginationConfig(
                commentsCount,
                category.commentsOrdering,
              );
              return {
                resourceId: p._id,
                ctxFields: getContextToken(p._id),
                pagination: paginationConfig,
                withVotes: p.commentingType === 'vote',
              };
            }),
          );
        });
        commentsPromise = isSSR(state) ? commentsRequest : commentsPromise;
      }

      return promise
        .then(() => finishLoading(dispatch, categoryId))
        .catch(() => finishLoading(dispatch, categoryId))
        .then(() => commentsPromise)
        .then(() => promise);
    },
  {
    cacheKeyBuilder: (args) => `${args.categoryId}${args.page}${args.sort}${args.siteMemberId}`,
    cacheTime: 1000,
    ignoreResolve: true,
  },
);
